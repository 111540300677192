import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import { formatters } from '../../common';
import persist from '../../utils/persist';
import Button from '../../components/Button/Button';
import VideoSettingsPopover from '../../components/Popovers/VideoSettingsPopover';
import Tooltip from '../../components/Tooltip/Tooltip';
import {
  Airplay,
  Chromecast,
  ChromecastConnected,
  Fullscreen,
  FullscreenExit,
  Forward,
  Settings,
  Pause,
  PictureInPicture,
  PlayArrow,
  Subtitles,
  Rewind,
  Volume,
  VolumeOff,
  VolumeDown,
  VolumeMute,
} from '../../icons';
import FullScreenHelper from '../../utils/FullScreenHelper';

import TimeSlider from './TimeSlider';
import VolumeSlider from './VolumeSlider';

const mobileInLandscape = window.matchMedia('screen and (max-width: 1023px) and (orientation: landscape)').matches;

// on mobile landscape, we remove the horizontal anchor offset to prevent it from clipping with a potential notch
const getAnchorOffset = () => ({ vertical: -30, horizontal: mobileInLandscape ? 0 : 25 });
const videoSettingsTransformOrigin = { vertical: 'top', horizontal: 'left' };

const componentDefinition = {
  // set default to true so the volume button is added
  isTouchDevice: true,
  pictureInPictureSupported: false,

  componentDidMount: function () {
    const { getService } = this.context,
      videoService = getService('video'),
      player = videoService.getPlayer();

    videoService.on('timeupdate', this.handleTimeUpdate);
    videoService.on('durationchange', this.handleDurationChange);
    videoService.on('volumechange', this.handleVolumeChange);
    videoService.on('chromecaststatechange', this.handleChromecastStateChange);
    videoService.on('airplaystatechange', this.handleAirplayStateChange);
    videoService.on('texttrackadd', this.handleTextTrackAdd);
    videoService.on('texttrackremove', this.handleTextTrackChange);
    videoService.on('texttrackchange', this.handleTextTrackChange);
    videoService.on('canplay', this.canPlay);

    const state = {
      showTimeSlider: getService('router').getRoute().name !== 'location-embedded',
    };

    if (player) {
      state.chromecastAvailable = player.cast.chromecast.state !== 'unavailable';
      state.airplayAvailable = player.cast.airplay.state !== 'unavailable';

      state.chromecastCasting = player.cast.chromecast.casting;
      state.airplayCasting = player.cast.airplay.casting;

      this.pictureInPictureSupported = player.pictureInPictureSupported;
    }

    this.setState(state);
  },

  componentWillMount: function () {
    this.isTouchDevice = this.context.getService('platform').isTouchDevice();

    FullScreenHelper.init();
    FullScreenHelper.addEventListener(this.handleFullScreenChange);

    this.state.fullscreenSupport = FullScreenHelper.hasSupport();
  },

  componentWillUnmount: function () {
    const { getService } = this.context,
      videoService = getService('video');

    videoService.off('timeupdate', this.handleTimeUpdate);
    videoService.off('durationchange', this.handleDurationChange);
    videoService.off('volumechange', this.handleVolumeChange);
    videoService.off('chromecaststatechange', this.handleChromecastStateChange);
    videoService.off('airplaystatechange', this.handleAirplayStateChange);
    videoService.off('texttrackadd', this.handleTextTrackAdd);
    videoService.off('texttrackremove', this.handleTextTrackChange);
    videoService.off('texttrackchange', this.handleTextTrackChange);
    videoService.off('canplay', this.canPlay);

    FullScreenHelper.removeEventListener(this.handleFullScreenChange);

    document.querySelector('.AppView').classList.remove('is-subtitled');
  },

  getInitialState: () => ({
    live: false,
    seeking: false,
    progress: 0,
    position: 0,
    duration: 0,
    settingsOpen: false,
    muted: false,
    volume: 1,
    fullscreen: false,
    fullscreenSupport: false,
    chromecastAvailable: false,
    chromecastCasting: false,
    chromecastName: '',
    airplayAvailable: false,
    airplayCasting: false,
    textTracks: [],
    activeTextTrack: null,
    firstPlay: true,
  }),

  handleChromecastStateChange: function (event) {
    const { getService } = this.context,
      player = getService('video').getPlayer();

    this.setState({
      chromecastAvailable: event.state !== 'unavailable',
      chromecastConnecting: event.state === 'connecting',
      chromecastCasting: event.state === 'connected',
      chromecastName: player.cast.chromecast.receiverName,
    });
  },

  handleAirplayStateChange: function (event) {
    this.setState({
      airplayAvailable: event.state !== 'unavailable',
      airplayCasting: event.state === 'connected',
    });
  },

  handleFullScreenChange: function (event) {
    this.setState({
      fullscreen: !!event.fullScreenElement,
    });
  },

  handleTimeUpdate: function (event) {
    const { getService } = this.context,
      videoService = getService('video'),
      player = videoService.getPlayer(),
      live = player.duration === Infinity;

    if (live) {
      const duration = player.seekable.length ? player.seekable.end(0) : 0;

      if (duration !== this.state.duration) {
        this.setState({ duration, live });
      }
    }

    if (event.currentTime === this.state.position) {
      return;
    }

    this.setState({
      position: event.currentTime,
    });
  },

  handleDurationChange: function (event) {
    const live = event.duration === Infinity;

    if (event.duration === this.state.duration) {
      return;
    }

    this.setState({
      duration: live ? 0 : event.duration,
      live,
    });
  },

  handleVolumeChange: function () {
    const { getService } = this.context,
      videoService = getService('video');

    this.setState({
      muted: videoService.getMuted(),
    });
  },

  handlePlayPauseClick: function () {
    const { getCursor, getService } = this.context,
      cursor = getCursor(['ui', 'video']),
      methodName = cursor.get('isPlaying') ? 'pause' : 'play',
      videoService = getService('video');

    if (this.state.firstPlay) {
      this.setState({ firstPlay: false });
    }

    videoService[methodName]();
  },

  handleRewindClick: function () {
    const { getService } = this.context,
      player = getService('video').getPlayer();

    if (player) {
      player.currentTime = Math.max(0, player.currentTime - 10);
    }
  },

  handleForwardClick: function () {
    const { getService } = this.context,
      player = getService('video').getPlayer();

    if (player) {
      player.currentTime = Math.min(this.state.duration, player.currentTime + 10);
    }
  },

  handleVolumeClick: function () {
    const { getService } = this.context,
      videoService = getService('video');

    videoService.setMuted(!videoService.getMuted());
  },

  handleChromecastClick: function () {
    const { getService } = this.context,
      player = getService('video').getPlayer();

    if (player.cast.chromecast.casting) {
      player.cast.chromecast.stop();
    } else {
      player.cast.chromecast.start();
    }
  },

  handleAirplayClick: function () {
    const { getService } = this.context,
      player = getService('video').getPlayer();

    if (player.cast.airplay.casting) {
      player.cast.airplay.stop();
    } else {
      player.cast.airplay.start();

      // fixes airplay popover not showing on mobile
      if (this.state.firstPlay && window.cordova) {
        this.handlePlayPauseClick();
      }
    }
  },

  handleSettingsClick: function (event) {
    this.setState({
      settingsOpen: true,
      settingsAnchor: event.target,
    });
  },

  handleSettingsClose: function () {
    this.setState({ settingsOpen: false });
  },

  handleFullScreenClick: function () {
    if (FullScreenHelper.fullScreenElement()) {
      FullScreenHelper.exitFullScreen();
    } else {
      FullScreenHelper.enterFullScreen(document.documentElement);
    }
  },

  handlePictureInPictureClick: function () {
    const { getService } = this.context;
    const player = getService('video').getPlayer();

    player?.togglePictureInPicture();
  },

  handleTextTrackAdd: function () {
    const player = this.context.getService('video').getPlayer();
    const textTracks = player.textTracks.filter((textTrack) => textTrack.kind === 'subtitles');
    const activeTextTrack = persist.getItem('theoplayer-active-texttrack');

    this.setState({
      textTracks,
      activeTextTrack: textTracks.find(({ label }) => label === activeTextTrack),
    });
  },

  handleTextTrackChange: function () {
    const player = this.context.getService('video').getPlayer();
    const textTracks = player.textTracks.filter((textTrack) => textTrack.kind === 'subtitles');

    this.setState({
      textTracks,
      activeTextTrack: textTracks.find(({ mode }) => mode === 'showing'),
    });
  },

  canPlay: function () {
    if (this.state.activeTextTrack) {
      this.setActiveTextTrack(this.state.textTracks[0]);
    }
  },

  handleTextTrackClick: function () {
    const player = this.context.getService('video').getPlayer();
    const { activeTextTrack, textTracks } = this.state;
    const playerIsReady = player.readyState > 1;

    let toActiveTextTrack = null;

    // toggle the active text track
    if (activeTextTrack) {
      persist.removeItem('theoplayer-active-texttrack');
    } else {
      persist.setItem('theoplayer-active-texttrack', this.state.textTracks[0].label);

      // activate the first texttrack
      toActiveTextTrack = textTracks[0];
    }

    // if the content is seekable, we can activate the texttracks. Otherwise, only activate the texttracks button and
    // delay the activation of the texttrack in the canPlay event.
    if (playerIsReady) {
      this.setActiveTextTrack(toActiveTextTrack);
    } else {
      this.setState({ activeTextTrack: toActiveTextTrack });
    }
  },

  setActiveTextTrack: function (textTrack) {
    this.state.textTracks.forEach((current) => {
      if (!textTrack || textTrack.id !== current.id) {
        current.mode = 'disabled';
      }
    });

    if (textTrack) {
      textTrack.mode = 'showing';
      document.querySelector('.AppView').classList.add('is-subtitled');
    } else {
      document.querySelector('.AppView').classList.remove('is-subtitled');
    }
  },

  goToLive: function () {
    const { getService } = this.context;

    getService('video').playLive();
  },

  onTimeSliderDragStart: function (event, progress) {
    this.setState({
      seeking: true,
      progress,
    });
  },

  onTimeSliderDragMove: function (event, progress) {
    this.setState({
      progress,
    });
  },

  onTimeSliderDragEnd: function (event, progress) {
    // prepare position for seek
    this.setState({
      position: this.state.duration * progress,
      seeking: false,
    });
  },

  renderVolumeIcon: function () {
    const { muted, volume } = this.state;

    if (muted) {
      return <VolumeOff />;
    }

    if (volume > 0.5) {
      return <Volume />;
    }

    return volume > 0.1 ? <VolumeDown /> : <VolumeMute />;
  },

  shouldComponentUpdate: function () {
    return true;
  },

  propTypes: {
    controls: PropTypes.bool,
  },

  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    pathWith: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  },
};

export default component('Controlbar', componentDefinition, function ({ className, audioOnly, debate, ...rest }) {
  const { getCursor } = this.context;
  const cursor = getCursor(['ui', 'video']);
  const isPlaying = cursor.get('isPlaying');
  const hasCaptions = this.state.textTracks.length > 0;
  const disableCaptions = !hasCaptions || audioOnly || this.state.airplayCasting;
  const isChromecastEnabled = this.state.chromecastAvailable && !audioOnly;
  const { live, duration, position, seeking, progress, muted } = this.state;

  let positionLabel = '';
  let canJumpToLive = false;

  if (live) {
    const liveOffset = seeking ? duration * (1 - progress) : duration - position;

    // only show live position when watching with 20 seconds delay or more
    if (liveOffset >= 20) {
      positionLabel = `-${formatters.formatDuration(liveOffset)}`;
      canJumpToLive = true;
    }
  } else if (duration > 0) {
    const positionForLabel = seeking ? progress * duration : position;

    positionLabel = `${formatters.formatDuration(positionForLabel)} / ${formatters.formatDuration(duration)}`;
  }

  return (
    <div className={classNames('Controlbar', className)} {...rest} data-control="true">
      <div className="Controlbar-timeSlider">
        {this.state.showTimeSlider && (
          <TimeSlider
            onDragStart={this.onTimeSliderDragStart}
            onDragMove={this.onTimeSliderDragMove}
            onDragEnd={this.onTimeSliderDragEnd}
            debate={this.props.debate}
          />
        )}
      </div>
      <div className="Controlbar-controls" onMouseLeave={() => this.setState({ volumeVisible: false })}>
        <Tooltip
          id="controlbar-play"
          title={isPlaying ? 'Video pauzeren' : 'Video starten'}
          position="top"
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorOffset={{ vertical: -20, horizontal: 'center' }}
        >
          <Button className="Controlbar-button Controlbar-buttonPlay" onClick={this.handlePlayPauseClick} aria-labelledby="controlbar-play">
            {isPlaying ? <Pause /> : <PlayArrow />}
          </Button>
        </Tooltip>
        {this.state.showTimeSlider && (
          <Tooltip
            id="controlbar-rewind"
            title="Terugspoelen"
            position="top"
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
          >
            <Button
              className="Controlbar-button Controlbar-rewind"
              onClick={this.handleRewindClick}
              children={<Rewind />}
              aria-labelledby="controlbar-rewind"
            />
          </Tooltip>
        )}
        {this.state.showTimeSlider && (
          <Tooltip
            id="controlbar-forward"
            title="Vooruitspoelen"
            position="top"
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
            disabled={!canJumpToLive}
          >
            <Button
              className="Controlbar-button Controlbar-buttonForward"
              onClick={this.handleForwardClick}
              children={<Forward />}
              aria-labelledby="controlbar-forward"
            />
          </Tooltip>
        )}
        {!this.isTouchDevice ? (
          <div className="Controlbar-volumeContainer" onMouseEnter={() => this.setState({ volumeVisible: true })}>
            <Tooltip
              id="controlbar-volume"
              title={muted ? 'Geluid aanzetten' : 'Geluid uitzetten'}
              position="top"
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              anchorOffset={{ vertical: -20, horizontal: 'center' }}
            >
              <Button
                className="Controlbar-button Controlbar-buttonVolume"
                onClick={this.handleVolumeClick}
                children={this.renderVolumeIcon()}
                aria-labelledby="controlbar-volume"
              />
            </Tooltip>
            <div className={classNames('Controlbar-volumeSlider', { hidden: !this.state.volumeVisible })}>
              <VolumeSlider onVolume={(volume) => this.setState({ volume })} />
            </div>
          </div>
        ) : null}
        {live ? (
          <Tooltip
            id="controlbar-live"
            title="Spring naar live"
            position="top"
            disabled={!canJumpToLive}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
          >
            <Button className="Controlbar-liveButton" disabled={!canJumpToLive} onClick={this.goToLive} aria-labelledby="controlbar-volume">
              <span className="Controlbar-liveButton-led" /> LIVE
            </Button>
          </Tooltip>
        ) : null}
        <div className="Controlbar-text">{positionLabel}</div>
        <div className="Controlbar-spacer">
          {this.state.chromecastCasting ? (
            <div className="Controlbar-info">
              <div className="Controlbar-title">{audioOnly ? 'Chromecast en Audiomodus actief' : 'Chromecast actief'}</div>
              <div className="Controlbar-subtitle">Speelt af op {this.state.chromecastName}.</div>
            </div>
          ) : audioOnly ? (
            <div className="Controlbar-info">
              <div className="Controlbar-title">Audiomodus actief</div>
              <div className="Controlbar-subtitle">U kunt de app nu op de achtergrond laten afspelen.</div>
            </div>
          ) : null}
        </div>
        {this.pictureInPictureSupported && !audioOnly && !this.state.airplayCasting && !this.state.chromecastCasting && (
          <Tooltip
            id="controlbar-picture-in-picture"
            title={'Minispeler'}
            position="top"
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
          >
            <Button className="Controlbar-button" onClick={this.handlePictureInPictureClick} aria-labelledby="controlbar-picture-in-picture">
              <PictureInPicture />
            </Button>
          </Tooltip>
        )}
        {this.state.airplayAvailable && !audioOnly && !this.state.chromecastCasting ? (
          <Tooltip
            id="controlbar-airplay"
            title={'Airplay'}
            position="top"
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
          >
            <Button
              className={classNames('Controlbar-button', { 'Controlbar-airplayActive': this.state.airplayCasting })}
              onClick={this.handleAirplayClick}
              children={<Airplay />}
              aria-labelledby="controlbar-airplay"
            />
          </Tooltip>
        ) : null}
        {isChromecastEnabled ? (
          <Tooltip
            id="controlbar-chromecast"
            title={'Chromecast'}
            position="top"
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
          >
            <Button
              className={classNames('Controlbar-button', {
                'Controlbar-cast-connecting': this.state.chromecastConnecting,
                'Controlbar-button--active': this.state.chromecastCasting,
              })}
              onClick={this.handleChromecastClick}
              children={this.state.chromecastCasting ? <ChromecastConnected /> : <Chromecast />}
              aria-labelledby="controlbar-chromecast"
            />
          </Tooltip>
        ) : null}
        {!disableCaptions ? (
          <Tooltip
            id="controlbar-captions"
            title={this.state.activeTextTrack ? 'Ondertiteling uitschakelen' : 'Ondertiteling inschakelen'}
            position="top"
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
          >
            <Button
              className={classNames('Controlbar-button', { 'Controlbar-button--active': this.state.activeTextTrack })}
              onClick={this.handleTextTrackClick}
              children={<Subtitles />}
              aria-labelledby="controlbar-captions"
            />
          </Tooltip>
        ) : null}
        <Tooltip
          id="controlbar-settings"
          title="Instellingen"
          position="top"
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorOffset={{ vertical: -20, horizontal: 'center' }}
        >
          <Button className="Controlbar-button" onClick={this.handleSettingsClick} children={<Settings />} aria-labelledby="controlbar-settings" />
        </Tooltip>
        {this.state.fullscreenSupport && !window.cordova ? (
          <Tooltip
            id="controlbar-fullscreen"
            title={this.state.fullscreen ? 'Normale weergave' : 'Vullende weergave'}
            position="top"
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOffset={{ vertical: -20, horizontal: 'center' }}
          >
            <Button className="Controlbar-button" onClick={this.handleFullScreenClick} aria-labelledby="controlbar-fullscreen">
              {this.state.fullscreen ? <FullscreenExit /> : <Fullscreen />}
            </Button>
          </Tooltip>
        ) : null}
      </div>
      <VideoSettingsPopover
        open={this.state.settingsOpen}
        anchor={this.state.settingsAnchor}
        anchorOffset={getAnchorOffset()}
        transformOrigin={videoSettingsTransformOrigin}
        onClose={this.handleSettingsClose}
        debate={debate}
      />
    </div>
  );
});
